<template>
  <el-collapse-item class="collapse_item" name="2">
    <template #title>
      <div class="collapse_item-title">
        {{ props.number }}. {{ $t("location") }}
        <span class="collapse_item-title_required">*</span>
      </div>
    </template>
    <div class="collapse_content">
      <div class="label">{{ $t("select_city") }}</div>
      <el-row :gutter="25" v-if="locations">
        <el-col :span="12">
          <el-form-item :prop="'country_id'" style="width: 100%">
            <el-select
              class="select_item-100 custom_input"
              v-model="propertyForm['country_id']"
              @change="clearState()"
              filterable=""
              :placeholder="$t('country')"
            >
              <el-option
                v-for="(item, index) in locations"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :prop="'state_id'"
            style="width: 100%"
            v-if="propertyForm['country_id'] !== null"
          >
            <el-select
              class="select_item-100 custom_input"
              v-model="propertyForm['state_id']"
              @change="clearCity()"
              filterable
              :placeholder="$t('region')"
            >
              <el-option
                v-for="(item, index) in locations[
                  findStatesIndex(propertyForm['country_id'])
                ].states"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item
            :prop="'city_id'"
            style="width: 100%"
            v-if="
              propertyForm['country_id'] !== null &&
              propertyForm['state_id'] !== null
            "
          >
            <el-select
              class="select_item-100 custom_input"
              v-model="propertyForm['city_id']"
              filterable
              :placeholder="$t('city')"
            >
              <el-option
                v-for="(item, index) in locations[
                  findStatesIndex(propertyForm['country_id'])
                ].states[
                  findCitiesIndex(
                    findStatesIndex(propertyForm['country_id']),
                    propertyForm['state_id']
                  )
                ].cities"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="25" v-if="!showDetailAddress">
        <div class="label">{{ $t("street_and_house") }}</div>
        <el-col>
          <el-form-item :prop="'location'" style="width: 100%">
            <el-input
              class="select_item-100 custom_input"
              placeholder="ул. Теряна 45/5а (второй подъезд)"
              :disabled="showDetailAddress"
              v-model="propertyForm['location']"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="label" v-if="!showDetailAddress">
        {{ $t("map_coordinates") }}
      </div>
      <el-row :gutter="25" v-if="!showDetailAddress">
        <el-col :span="12">
          <el-form-item :prop="'latitude'" style="width: 100%">
            <el-input
              class="select_item-100 custom_input"
              placeholder="latitude"
              :disabled="showDetailAddress"
              v-model="propertyForm['latitude']"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :prop="'longtitude'" style="width: 100%">
            <el-input
              class="select_item-100 custom_input"
              placeholder="longtitude"
              :disabled="showDetailAddress"
              v-model="propertyForm['longtitude']"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-checkbox v-model="showDetailAddress">{{
        $t("hide_exact_address")
      }}</el-checkbox>
    </div>
  </el-collapse-item>
</template>

<script setup>
import { ref, inject, onMounted, watch, defineProps } from "vue";

import { useRouter } from "vue-router";

const router = useRouter();

const propertyForm = inject("PropertyForm");
const props = defineProps(["number"]);
const locations = ref([]);

const showDetailAddress = ref(false);

const clearState = () => {
  propertyForm["state"] = null;
};

const clearCity = () => {
  propertyForm["city"] = null;
};

const findStatesIndex = (countryId) => {
  return locations.value.findIndex((item) => item.id === countryId);
};

const findCitiesIndex = (state_index, stateId) => {
  return locations.value[state_index].states.findIndex(
    (item) => item.id === stateId
  );
};

const getLocations = async () => {
  const res = await fetch(
    process.env.VUE_APP_BACK_URL + "/api/property/locations",
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("garant_token"),
        Accept: "application/json; charset=utf-8",
      },
      method: "GET",
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      router.push({ name: "login" });
    }
  } else {
    let result = await res.json();

    locations.value = result.data;
  }
};

onMounted(() => {
  getLocations();
});

watch(
  () => propertyForm,
  (newValue, oldValue) => {
    console.log(newValue, oldValue);
  },
  { deep: true }
);
</script>

<style lang="scss" scoped></style>
