<template>
  <div class="personal_left">
    <div class="personal_left-block" style="margin-bottom: 40px">
      <button class="personal_left_button" @click="goHome()">
        {{ $t("my_account") }}
        <img src="../assets/black-arrow-right.svg" alt="" />
      </button>
    </div>
    <!--        <div class="personal_left-block" style="margin-bottom: 30px">-->
    <!--            <button class="personal_left_button">-->
    <!--                {{ $t("orders") }}-->
    <!--                <img-->
    <!--                    src="/themes/new/img/personal/black-arrow-right.svg"-->
    <!--                    alt=""-->
    <!--                />-->
    <!--            </button>-->
    <!--            <button class="personal_left_button">-->
    <!--                {{ $t("my_ads") }}-->
    <!--                <img-->
    <!--                    src="/themes/new/img/personal/black-arrow-right.svg"-->
    <!--                    alt=""-->
    <!--                />-->
    <!--            </button>-->
    <!--        </div>-->
    <div class="personal_left-block" style="margin-bottom: 30px">
      <!-- <button class="personal_left_button">
                {{ $t("favorites") }}
                <img
                    src="../assets/black-arrow-right.svg"
                    alt=""
                />
            </button> -->
      <!--            <button class="personal_left_button">-->
      <!--                {{ $t("action_log") }}-->
      <!--                <img-->
      <!--                    src="/themes/new/img/personal/black-arrow-right.svg"-->
      <!--                    alt=""-->
      <!--                />-->
      <!--            </button>-->
      <!-- <button class="personal_left_button">
                {{ $t("settings") }}
                <img
                    src="../assets/black-arrow-right.svg"
                    alt=""
                />
            </button> -->
    </div>
    <div class="personal_left-block">
      <button
        class="personal_left_button"
        @click="logout()"
        :disabled="loading"
      >
        {{ $t("logout") }}
        <img src="../assets/black-arrow-right.svg" alt="" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {useRouter } from "vue-router";
import { ElNotification } from "element-plus";

const router = useRouter();

const loading = ref(false);

const goHome = () => {
  router.push({ name: "home" });
}

const logout = async () => {
  const res = await fetch(process.env.VUE_APP_BACK_URL + "/api/v1/logout", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("garant_token"),
      Accept: "application/json; charset=utf-8",
    },
    method: "GET",
  });

  if (!res.ok) {
    if (res.status === 401) {
      router.push({ name: "login" });
    }
  } else {
    const result = await res.json();

    if (!result.error) {
      ElNotification({
        type: "success",
        title: "Успех",
        message: result.message,
        position: "bottom-right",
      });
      loading.value = false;
      location.reload();
    } else {
      ElNotification({
        type: "error",
        title: "Ошибка",
        message: result.message,
        position: "bottom-right",
      });
      loading.value = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.personal_left {
  border-radius: 8px;
  border: 1px solid #f8f8f8;
  padding: 15px;
  display: flex;
  flex-direction: column;

  &_button {
    padding: 0px 19px 0px 25px;
    width: 200px;
    height: 50px;
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 10px;
    border: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: space-between;
    &:hover {
      color: #ff24af;
      transition-duration: 0.3s;
    }
  }
}
</style>
