<template>
  <el-collapse-item class="collapse_item" name="4">
    <template #title>
      <div class="collapse_item-title">
        {{ props.number }}. {{ $t("about_plot") }}
        <span class="collapse_item-title_required">*</span>
      </div>
    </template>
    <div class="collapse_content">
      <div class="label">{{ $t("land_type") }}</div>
      <el-row>
        <el-col>
          <el-form-item :prop="'area_id'" style="width: 100%">
            <el-select
              class="select_item-100 custom_input"
              v-model="propertyForm['area_id']"
              filterable
              :placeholder="$t('select_from_list')"
            >
              <el-option
                v-for="item in typeareas"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="label">{{ $t("plot_area") }}</div>
      <el-row>
        <el-col>
          <el-form-item :prop="'area_square'" style="width: 100%">
            <el-input
              class="select_item-100 custom_input"
              :placeholder="$t('specify_plot_area')"
              v-model="propertyForm['area_square']"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </el-collapse-item>
</template>

<script setup>
import { defineProps, inject, ref, onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps(["number"]);

const propertyForm = inject("PropertyForm");

const typeareas = ref([]);

const getAreatypes = async () => {
  const res = await fetch(process.env.VUE_APP_BACK_URL + "/api/property/area", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("garant_token"),
      Accept: "application/json; charset=utf-8",
    },
    method: "GET",
  });
  if (!res.ok) {
    if (res.status === 401) {
      router.push({ name: "login" });
    }
  } else {
    const result = await res.json();
    typeareas.value = result.data;
  }
};

onMounted(() => {
  getAreatypes();
});
</script>

<style lang="scss" scoped></style>
