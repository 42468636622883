<template>
  <el-collapse-item class="collapse_item" :title="$t('my_ads')" name="2">
    <div class="properties">
      <div class="properties_list">
        <div class="properties_list-swiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(card, index) in myProperties"
              v-show="card.status.value === 'approved'"
              :key="index"
            >
              <PropertyCard :card="card"></PropertyCard>
            </div>
          </div>
        </div>
      </div>
      <router-link :to="{ name: 'add' }" class="properties_empty">
        <div class="properties_empty-link">{{ $t("add_listing") }}</div>
      </router-link>
    </div>
  </el-collapse-item>
</template>

<script setup>
import { inject, onMounted } from "vue";
import Swiper from "swiper";

import PropertyCard from "./PropertyCard.vue";

const myProperties = inject("MyProperties");

const initSwiper = () => {
  new Swiper(".properties_list-swiper", {
    slidesPerView: "auto",
    centeredSlides: false,
    // initialSlide: 1,
    // loop: true,
    spaceBetween: 15,
    pagination: false,
    navigation: false,
    breakpoints: {
      575: {
        slidesPerView: "auto",
      },
      320: {
        centeredSlides: true,
        slidesPerView: "auto",
      },
    },
  });
};

onMounted(() => {
  initSwiper();
});
</script>

<style lang="scss">
.properties {
  margin-top: 20px;
  &_empty {
    margin-top: 30px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    height: 44px;
    border-radius: 12px;
    border: 1px solid #2d84ec;
    background: #ebf4ff;

    &-link {
      color: #2d84ec;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .swiper-slide {
      width: 250px;
    }
  }
}
</style>
