<template>
  <div class="property">
    <div class="container">
      <StagesCreate></StagesCreate>
      <CategoriesProperty v-if="stepNumber === 1"></CategoriesProperty>
      <el-form
        ref="propertyFormRef"
        :model="propertyForm"
        :rules="rules"
        label-position="top"
        label-width="auto"
        :inline="false"
        size="default"
      >
        <el-collapse v-model="activeNames" v-if="stepNumber === 2">
          <StatusProperty></StatusProperty>
          <NameProperty :number="1"></NameProperty>
          <LocationsProperty :number="2"></LocationsProperty>
          <CommunicationProperty :number="3"></CommunicationProperty>
          <AboutProperty :number="4"></AboutProperty>
          <PriceProperty :number="5"></PriceProperty>
          <ImagesProperty :number="6"></ImagesProperty>
          <!--                <contacts :number="7"></contacts>-->
          <DescriptionProperty :number="7"></DescriptionProperty>
          <VideoLinksProperty :number="8"></VideoLinksProperty>
          <!--                <documents :number="10"></documents>-->
        </el-collapse>

        <el-form-item
          class="el_form-submit"
          style="width: 100%"
          v-if="stepNumber === 2"
        >
          <el-button
            class="property_next"
            :loading="loading"
            type="primary"
            @click="onSubmit(propertyFormRef)"
            >Далее</el-button
          >
        </el-form-item>
      </el-form>
      <!-- <div class="test" v-if="stepNumber === 3">Пункт проверки</div> -->
      <FinalProperty v-if="stepNumber === 3"></FinalProperty>
    </div>
    <div class="container">
      <div
        v-if="stepNumber === 1"
        class="property_next"
        @click="changeStepNumber(stepNumber + 1)"
      >
        Далее
      </div>
      <router-link v-if="stepNumber === 3" :to="{ name: 'home' }" class="property_next">На главную</router-link>
    </div>
  </div>
</template>

<script setup>
import { provide, ref, reactive, onMounted } from "vue";
import { ElNotification } from "element-plus";
import { useRouter } from "vue-router";
import StagesCreate from "@/components/StagesCreate.vue";
import CategoriesProperty from "@/components/property/CategoriesProperty.vue";
import StatusProperty from "@/components/StatusProperty.vue";
import NameProperty from "@/components/property/NameProperty.vue";
import LocationsProperty from "@/components/property/LocationsProperty.vue";
import CommunicationProperty from "@/components/property/CommunicationProperty.vue";
import AboutProperty from "@/components/property/AboutProperty.vue";
import PriceProperty from "@/components/property/PriceProperty.vue";
import ImagesProperty from "@/components/property/ImagesProperty.vue";
import DescriptionProperty from "@/components/property/DescriptionProperty.vue";
import VideoLinksProperty from "@/components/property/VideoLinksProperty.vue";
import FinalProperty from "@/components/FinalProperty.vue";


const router = useRouter();
const stepNumber = ref(1);
const selectedCategory = ref(null);
const activeNames = ref(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]);
const rules = reactive({});
const propertyFormRef = ref();
const propertyForm = reactive([]);
const loading = ref(false);
const createdProperty = ref([]);

const SelectCategory = function (value) {
  selectedCategory.value = value;
  stepNumber.value = 2;
};

const objectToFormData = (obj, formData, parentKey) => {
  formData = formData || new FormData();
  for (let key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const propName = parentKey ? `${parentKey}[${key}]` : key;
      const value = obj[key];

      if (value instanceof File) {
        // Если значение является файлом, добавляем его напрямую в FormData
        formData.append(propName, value);
      } else if (value instanceof Object && !(value instanceof File)) {
        // Если значение является объектом, рекурсивно вызываем функцию
        if (value[0] instanceof Date) {
          formData.append(`${propName}[start]`, value[0]);
          formData.append(`${propName}[end]`, value[1]);
        } else if (value instanceof Date) {
          formData.append(propName, value);
        } else {
          objectToFormData(value, formData, propName);
        }
      } else {
        // В противном случае добавляем обычное значение в FormData
        formData.append(propName, value);
      }
    }
  }
  return formData;
};

const submitForm = async (formData) => {
  loading.value = true;

  let res = await fetch(process.env.VUE_APP_BACK_URL + "/api/property/create", {
    headers: {
      Authorization: 'Bearer '+ localStorage.getItem('garant_token'),
      Accept: "application/json; charset=utf-8",
    },
    method: "POST",
    body: objectToFormData(formData),
  });

  if (!res.ok) {
    if (res.status === 401) {
      router.push({ name: "login" });
    }
  } else {
    let result = await res.json();

    if (result.data) {
      ElNotification({
        type: "success",
        title: "Успех",
        message: "Объявление создано успешно",
        position: "bottom-right",
      });
      loading.value = false;

      changeStepNumber(3);
      createdProperty.value = result.data;
    } else {
      ElNotification({
        type: "error",
        title: "Ошибка",
        message: "Ошибка при создании объявления",
        position: "bottom-right",
      });
      loading.value = false;
    }
  }
};

const onSubmit = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      submitForm(propertyForm);
    } else {
      console.log("error submit!", fields);
    }
  });
};

const makeForm = () => {
  propertyForm["name"] = null;
  addRule("name", true, "Заполните название");

  propertyForm["country_id"] = null;
  addRule("country_id", true, "Укажите страну");

  propertyForm["state_id"] = null;
  addRule("state_id", true, "Укажите регион");

  propertyForm["city_id"] = null;
  addRule("city_id", true, "Укажите город");

  addRule("location", false, "Укажите адрес");
  addRule("latitude", false, "Укажите широту");
  addRule("longtitude", false, "Укажите долготу");
  addRule("features", true, "Выберите удобства");
  addRule("area_id", true, "Укажите тип помещения");
  addRule("area_square", true, "Укажите площадь участка");
  addRule("price", false, "Укажите цену продажи");
  addRule("change_content", false, "Укажите цену обмена");
  addRule("price_rent_normal", false, "Укажите цену аренды");

  propertyForm["images"] = [];
  addRule("images", true, "Добавьте картинки");

  addRule("description", true, "Добавьте описание");

  propertyForm["video_links"] = [];
  addRule("video_links", true, "Добавьте ссылки на видео");
};

const addRule = (key, required, message, trigger = "change") => {
  rules[key] = [
    {
      required: required,
      message: message,
      trigger: trigger,
    },
  ];
};

const changeStepNumber = (value) => {
  stepNumber.value = value;
};
const goBack = () => {
  history.go(-1);
};

onMounted(() => {
  makeForm();
});


provide("GoBack", goBack);
provide("StepNumber", stepNumber);
provide("SelectedCategory", selectedCategory);
provide("SelectCategory", SelectCategory);
provide("PropertyForm", propertyForm);
provide("CreatedProperty", createdProperty);
</script>

<style lang="scss">
.property {
  //margin-top: 30px;

  // .el-collapse-item {
  //     border
  // }
  &_next {
    height: 44px;
    background-color: #2d84ec;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 12px;
    cursor: pointer;
    color: #f8f8f8;
  }

  .el-collapse-item__header {
    height: auto;
    border-bottom: none;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 30px;
  }

  .el-collapse-item__wrap {
    border-bottom: none;
    overflow: visible;
  }

  .el-collapse {
    margin-top: 38px;
    border-top: 0px;
    border-bottom: 0px;
  }

  .collapse_item-title {
    font-size: 16px;
    font-weight: 600;
    &_required {
      color: #f00;
    }
  }

  .collapse_content {
    border-radius: 20px;
    background: #f8f8f8;
    padding: 20px;
  }

  .el-row {
    margin-bottom: 20px;
  }

  .select_item-100 {
    width: 100%;
  }

  .custom_input {
    input,
    textarea {
      border-radius: 12px;
    }
  }

  .label {
    font-size: 14px;
    margin-bottom: 18px;
  }
  .el-upload-dragger {
    height: 50px;
  }
}
</style>
