<template>
  <div class="main_page">
    <user-info></user-info>
    <!-- <user-progress></user-progress> -->
    <el-collapse v-model="activeNames">
      <OrdersProperty></OrdersProperty>
      <MyProperties></MyProperties>
      <!-- <FavoritesProperty></FavoritesProperty> -->
    </el-collapse>
  </div>
</template>

<script setup>
import { ref, provide, onMounted } from "vue";
import { useRouter } from "vue-router";

import UserInfo from "@/components/UserInfo.vue";
import OrdersProperty from "@/components/OrdersProperty.vue";
import MyProperties from "@/components/MyProperties.vue";
// import FavoritesProperty from "@/components/FavoritesProperty.vue";

const myProperties = ref([]);
const router = useRouter();

const getMyProperties = async () => {
  const res = await fetch(
    process.env.VUE_APP_BACK_URL + "/api/personal/properties",
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("garant_token"),
        Accept: "application/json; charset=utf-8",
      },
      method: "GET",
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      router.push({ name: "login" });
    }
  } else {
    const result = await res.json();
    myProperties.value = result.data;
  }
};

onMounted(() => {
  getMyProperties();
});

provide("MyProperties", myProperties);
</script>

<style lang="scss">
.personal {
  margin-top: 50px;
  .main_block {
    border-radius: 8px;
    border: 1px solid #f8f8f8;
    padding: 30px;
    margin: 0px 24px 0px 24px;
  }

  .el-collapse-item {
    padding: 15px 25px;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    margin-bottom: 10px;
  }

  .el-collapse-item__header {
    height: auto;
    border-bottom: none;
    font-size: 14px;
    line-height: normal;
  }

  .el-collapse-item__wrap {
    border-bottom: none;
  }

  .el-collapse {
    margin-top: 38px;
    border-top: 0px;
    border-bottom: 0px;
  }
}
</style>
