import { createI18n } from 'vue-i18n'

const messages = {
    en: require('./lang/en.json'),
    ru: require('./lang/ru.json'),
    hy: require('./lang/hy.json'),
    ka: require('./lang/ka.json'),
};

const i18n = createI18n({
    locale: 'ru', // Выбирайте начальный язык или значение по умолчанию
    messages,
});

export default i18n;
