<template>
    <el-collapse-item class="collapse_item" name="6">
        <template #title>
            <div class="collapse_item-title">
                {{props.number}}. {{ $t("photos") }}
                <span class="collapse_item-title_required">*</span>
            </div>
        </template>
        <div class="collapse_content">
            <div class="image_upload">
                <div class="image_upload-window" v-if="propertyForm['images'].length">
                    <draggable
                        v-model="propertyForm['images']"
                        :options="{ animation: 150 }"
                        class="upload_list"
                    >
                        <template #item="{element, index}">
                            <div class="upload_list-item">
                                <el-icon @click="removeImage(index)" class="upload_list-item__remove" ><Remove /></el-icon>
                                <img
                                    class="upload_list-item__img"
                                    :src="previewImage(element)"
                                />
                            </div>
                        </template>

                    </draggable>
                </div>
                <label class="image_upload-button">
                    <i class="el-icon-camera"></i>
                    {{ $t("upload_photos") }}
                    <el-form-item :prop="'images'">
                        <input
                        type="file"
                        accept="image/jpeg, image/png"
                        style="display: none"
                        ref="fileInput"
                        @change="handleFileUpload"
                    />
                    </el-form-item>
                </label>
                <div class="image_upload-description">
                    {{ $t("arrange_photos") }}
                </div>
            </div>
        </div>
    </el-collapse-item>
</template>


<script setup>
    import draggable from "vuedraggable";

    import {defineProps, inject} from "vue";

    const props = defineProps(['number']);

    const propertyForm = inject("PropertyForm");
    
    const previewImage = (file) => {
        return URL.createObjectURL(file);
    }

    const handleFileUpload = (event) => {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
            propertyForm['images'].push(files[i]);
        }
    }

    const removeImage = (index) => {
        propertyForm['images'].splice(index, 1);
    }

</script>

<style lang="scss" scoped>
.image_upload {
    &-window {
        background-color: #fff;
        padding: 15px;
        border: 1px dashed #d5d8de;
        border-radius: 12px;
        .upload_list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 13px;
            &-item {
                position: relative;
                &__remove {
                    position: absolute;
                    right: -5px;
                    top: -5px;
                    color: red;
                    cursor: pointer;
                }
                &__img {
                    width: 85px;
                    height: 64px;
                    border-radius: 8px;
                    box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.80);
                    object-fit: cover;
                }
            }
        }
    }
    &-button {
        cursor: pointer;
        margin-top: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #2d84ec;
        font-size: 14px;
        gap: 15px;
    }
    &-description {
        margin-top: 25px;
        font-size: 12px;
        color: #d5d8de;
        text-align: center;
    }
}
</style>
