<template>
    <div class="auth">
        <RouterView />
    </div>
</template>

<script setup>

</script>

<style lang="scss">

    .auth {
        width: 100%;
        height: 100%;
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .auth_form {
         margin-top: 200px;
         padding: 20px;
         border: 1px solid #ccc;
         border-radius: 5px;
         box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
         margin: 0 auto;
         width: 100%;
         max-width: 600px;
         text-align: center;
        

         .form_logo {
            width: 300px;
         }
    }
</style>