<template>
    <div class="property_card">
        <img :src="props.card.picture" alt="" class="property_card-image" />
        <div class="property_card-name">{{ props.card.name }}</div>
        <div class="property_card-status">
            <div class="status_title">{{ $t("status") }}</div>
            <div class="status_value" :style="{ color: props.card.status_color }">
                {{props.card.status.label }}
            </div>
        </div>
        <a :href="backUrl + props.card.url" target="_blank" class="property_card-show">{{
            $t("show")
        }}</a>
        <router-link :to="'/personal/edit/'+props.card.id" class="property_card-edit">Редактировать</router-link>
    </div>
</template>


<script setup>
    import { defineProps } from "vue";

    const backUrl = process.env.VUE_APP_BACK_URL;

    const props = defineProps(['card']);

</script>

<style lang="scss" scoped>
.property_card {
    padding: 18px;
    height: 280px;
    width: 229px;
    max-width: 210px;
    border-radius: 12px;
    border: 1px solid #e6e6e6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-image {
        width: 100%;
        height: 129px;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0px 1px 11px 3px rgba(0, 0, 0, 0.05);
    }

    &-name {
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-status {
        display: flex;
        justify-content: center;
        .status {
            &_title {
                font-size: 12px;
                opacity: 0.3;
                margin-right: 13px;
            }
            &_value {
                font-size: 12px;
            }
        }
    }

    &-show {
        width: 100%;
        height: 38px;
        border-radius: 12px;
        background: #2d84ec;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #fff;
    }

    &-edit {
        width: 100%;
        height: 38px;
        border-radius: 12px;
        background: #e79819;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #fff;
    }
}
</style>
