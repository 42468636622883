<template>
  <div class="auth_form">
    <img src="../../assets/logo-garant.am.png" alt="" class="form_logo" />
    <el-form
      :rules="rules"
      :model="loginForm"
      :label-position="'top'"
      label-width="auto"
      ref="loginRef"
      style="max-width: 600px"
    >
      <el-form-item label="Логин" :prop="'email'">
        <el-input v-model="loginForm['email']" autocomplete="on"  />
      </el-form-item>
      <el-form-item label="Пароль" :prop="'password'">
        <el-input v-model="loginForm['password']" type="password" />
      </el-form-item>
      <!-- <RouterLink :to="'/auth/register'"
        >Нет аккаунта? Зерегистрироваться!</RouterLink
      > -->
      <el-form-item>
        <el-button color="#ff169b" :loading="loading" type="primary" @click="onSubmit(loginRef)"
          >Войти</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { ElNotification } from "element-plus";

const loginForm = reactive([]);
const rules = reactive({});
const loginRef = ref();
const loading = ref(false);

rules["email"] = [
  {
    required: true,
    message: "Заполните логин",
    trigger: "change",
  },
];
rules["password"] = [
  {
    required: true,
    message: "Заполните пароль",
    trigger: "change",
  },
];

const objectToFormData = (obj) => {
  const formData = new FormData();
  for (let key in obj) {
    formData.append(key, obj[key]);
  }
  return formData;
};

const submitForm = async (formData) => {
  loading.value = true;

  let response = await fetch(
    process.env.VUE_APP_BACK_URL + "/api/v1/login",
    {
      method: "POST",
      body: objectToFormData(formData),
    }
  );
  let result = await response.json();

  if (!result.error) {
    ElNotification({
      type: "success",
      title: "Успех",
      message: "Успешная авторизация",
      position: "bottom-right",
    });
    loading.value = false;
    localStorage.setItem("garant_token", result.data.token);
    location.href = "/";
  } else {
    ElNotification({
      type: "error",
      title: "Ошибка",
      message: result.message,
      position: "bottom-right",
    });
    loading.value = false;
  }
};

const onSubmit = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      submitForm(loginForm);
    } else {
      console.log("error submit!", fields);
    }
  });
};
</script>

<style lang="scss"></style>
