<template>
  <el-collapse-item class="collapse_item" name="3">
    <template #title>
      <div class="collapse_item-title">
        {{ props.number }}. {{ $t("communications") }}
      </div>
    </template>
    <div class="collapse_content">
      <el-row v-if="communications.length">
        <el-col>
          <el-form-item :prop="'features'" style="width: 100%">
            <el-select
              v-model="propertyForm['features']"
              multiple
              filterable
              :placeholder="$t('select_communications')"
              class="select_item-100 custom_input"
            >
              <el-option
                v-for="item in communications"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </el-collapse-item>
</template>

<script setup>
import { ref, onMounted, inject, defineProps } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps(["number"]);

const selectedCategory = inject("SelectedCategory");
const propertyForm = inject("PropertyForm");

const communications = ref([]);

const getCommunications = async () => {
  const res = await fetch(
    process.env.VUE_APP_BACK_URL +
      "/api/property/features/" +
      selectedCategory.value,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("garant_token"),
        Accept: "application/json; charset=utf-8",
      },
      method: "GET",
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      router.push({ name: "login" });
    }
  } else {
    const result = await res.json();
    communications.value = result.data;
  }
};

onMounted(() => {
  getCommunications();
});
</script>

<style lang="scss" scoped></style>
