<template>
    <el-collapse-item class="collapse_item" name="8">
        <template #title>
            <div class="collapse_item-title">
                {{props.number}}. {{ $t("title") }}
                <span class="collapse_item-title_required">*</span>
            </div>
        </template>
        <div class="collapse_content">
            <el-row :gutter="25">
              <el-col>
                <el-form-item :prop="'name'" style="width:100%">
                  <el-input
                      class="custom_input"
                      v-model="propertyForm['name']"
                      type="text"
                      :placeholder="$t('title')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
        </div>
    </el-collapse-item>
</template>

<script setup>

import {defineProps, inject} from 'vue';

const props = defineProps(['number']);

const propertyForm = inject('PropertyForm');

</script>

<style lang="scss" scoped>
</style>
