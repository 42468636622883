<template>
    <div class="final">
        <div class="final_window">
            <i class="el-icon-success final_window-icon"></i>
            <div class="final_window-content">
                <div class="content_header">Готово</div>
                <div class="content_text">
                    После проверки модератором, объявление добавиться на сайт
                </div>
            </div>
        </div>
        <div class="final_property">
            <img class="final_property-image" :src="createdProperty.picture" />
            <div class="final_property-block">
                <div class="final_header">{{ createdProperty.name }}</div>
                <div class="final_status">
                    статус <span class="final_status-color">модерация</span>
                </div>
                <a :href="createdProperty.url" class="final_to">Посмотреть</a>
            </div>
        </div>
        <!-- <el-collapse v-model="activeNames">
            <el-collapse-item class="collapse_item" name="1">
                <template slot="title">
                    <div class="collapse_item-title">Платные услуги</div>
                </template>
                <div class="final_collapse-content">
                    <div class="services">
                        <div class="services_item">
                            <img
                                class="service_item-img"
                                src="https://img.freepik.com/premium-photo/view-of-the-green-mountains-and-hills-at-sunset_545689-8154.jpg"
                            />
                            <div class="service_item-header">Тариф - Старт</div>
                            <div class="service_item-features">
                                <div class="feature_item">
                                    <i
                                        class="el-icon-success feature_item-icon"
                                    ></i>
                                    <div class="feature_item-content">
                                        Бесплатный приезд фотографа
                                    </div>
                                </div>
                                <div class="feature_item">
                                    <i
                                        class="el-icon-success feature_item-icon"
                                    ></i>
                                    <div class="feature_item-content">
                                        Фотографирование участка
                                    </div>
                                </div>
                                <div class="feature_item">
                                    <i
                                        class="el-icon-success feature_item-icon"
                                    ></i>
                                    <div class="feature_item-content">
                                        Цветокоррекция и обработка фотографий
                                    </div>
                                </div>
                                <div class="feature_item">
                                    <i
                                        class="el-icon-success feature_item-icon"
                                    ></i>
                                    <div class="feature_item-content">
                                        Обозначение участка на ландшафте
                                    </div>
                                </div>
                                <div class="feature_item">
                                    <i
                                        class="el-icon-success feature_item-icon"
                                    ></i>
                                    <div class="feature_item-content">
                                        Фотографии с авторским логотипом от
                                        garant.am
                                    </div>
                                </div>
                                <div class="feature_item">
                                    <i
                                        class="el-icon-success feature_item-icon"
                                    ></i>
                                    <div class="feature_item-content">
                                        Срок выполнения 2-5 дней
                                    </div>
                                </div>
                            </div>
                            <div class="service_item-diss">
                                <div class="diss_item">
                                    <i class="el-icon-error diss_item-icon"></i>
                                    <div class="diss_item-content">
                                        Видео участка
                                    </div>
                                </div>
                                <div class="diss_item">
                                    <i class="el-icon-error diss_item-icon"></i>
                                    <div class="diss_item-content">
                                        Обозначение участка на видео
                                    </div>
                                </div>
                                <div class="diss_item">
                                    <i class="el-icon-error diss_item-icon"></i>
                                    <div class="diss_item-content">
                                        Фотографии и Видео без логотипа
                                        garant.am
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-collapse-item>
        </el-collapse> -->
    </div>
</template>

<script setup>
    import { inject } from 'vue';

    const createdProperty = inject('CreatedProperty');

</script>

<style lang="scss">
.final {
    &_window {
        margin: 40px 0px;
        height: 90px;
        padding: 15px 27px;
        border-radius: 14px;
        background: #b2efd2;
        display: flex;
        align-items: center;
        &-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 30px;
            .content_header {
                font-size: 15px;
                font-weight: 600;
                margin-bottom: 7px;
            }
            .content_text {
                font-size: 12px;
                font-weight: 400;
                color: #919191;
            }
        }
        &-icon {
            font-size: 44px;
            color: #20c374;
        }
    }
    &_property {
        padding: 27px 20px;
        margin-bottom: 40px;
        display: flex;
        gap: 25px;
        height: 148px;
        border-radius: 12px;
        border: 1px solid #e6e6e6;
        &-image {
            border-radius: 8px;
            height: 100%;
            object-fit: cover;
        }
        &-block {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            .final_header {
                font-size: 14px;
                font-weight: 600;
            }
            .final_status {
                font-size: 12px;
                color: #cccccc;
                &-color {
                    color: #f90;
                }
            }
            .final_to {
                background-color: #ebf2ff;
                border-radius: 8px;
                height: 32px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #2d84ec;
                font-size: 12px;
                cursor: pointer;
                &:hover {
                    background-color: #dce6f8;
                    transition-duration: 0.3s;
                }
            }
        }
    }

    .services {
        display: flex;
        &_item {
            display: flex;
            flex-direction: column;
        }
    }
}
</style>
