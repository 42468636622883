<template>
  <div class="categories">
    <el-page-header @back="goBack" :content="$t('select_category')" :title="''">
    </el-page-header>
    <div class="categories_list" v-if="categories.length">
      <button
        class="categories_list-item"
        v-for="item in categories"
        :key="item.id"
        @click="SelectCategory(item.id)"
      >
        {{ item.name }}<i class="el-icon-arrow-right"></i>
      </button>
    </div>
    <el-skeleton class="skeleton_personal" v-else :rows="5" animated />
  </div>
</template>

<script setup>
import { onMounted, ref, inject } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const categories = ref([]);

const SelectCategory = inject("SelectCategory");
const goBack = inject("GoBack");

const getCategories = async () => {
  const res = await fetch(
    process.env.VUE_APP_BACK_URL + "/api/property/categories",
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("garant_token"),
        Accept: "application/json; charset=utf-8",
      },
      method: "GET",
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      router.push({ name: "login" });
    }
  } else {
    const result = await res.json();
    categories.value = result.data;
  }
};

onMounted(() => {
  getCategories();
});
</script>

<style lang="scss" scoped>
.skeleton_personal {
  margin: 40px 0px;
}
.categories {
  margin-top: 35px;

  &_list {
    margin: 40px 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-item {
      width: 100%;
      border-radius: 12px;
      border: 1px solid #e6e6e6;
      background: #fff;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 25px;
      font-size: 14px;
      max-width: 300px;
      &:hover {
        background: #e5e5e5;
        transition-duration: 0.5s;
      }
    }
  }
}
</style>

<!-- <script>
import store from "../store";
import { mapGetters } from "vuex";
import SearchInput from "../components/SearchInput.vue";
import Skeleton from "../components/Skeleton.vue";
import { ElNotification } from "element-plus";

export default {
    name: "Home",
    components: { SearchInput, Skeleton },

    computed: {
        ...mapGetters("main", ["wiki", "articles", "testsStatus", "loading"]),
    },
    // beforeRouteEnter(to, from, next){
    //     store.dispatch('main/fetchMain');
    //     next();
    // },
    // created() {
    //     console.log(321)
    //     this.fetchData();
    // },
    methods: {
        courseFilter(filter, value) {
            if (!this.loading) {
                if (value !== 0) {
                    store.commit("education/updateFilter", filter);
                    this.$router.push({ name: "education" });
                } else {
                    ElNotification({
                        title: "Предупреждение",
                        message: "Нет курсов в выбранном фильтре",
                        type: "warning",
                        customClass: "isnotification",
                        position: "bottom-right",
                        offset: 10,
                    });
                }
            } else {
                ElNotification({
                    title: "Ошибка",
                    message: "Курсы не загружены",
                    type: "error",
                    customClass: "isnotification",
                    position: "bottom-right",
                    offset: 10,
                });
            }
        },
    },
    setup() {
        store.dispatch("main/fetchMain");
    },
};
</script> -->
