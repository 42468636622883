import {  createWebHistory, createRouter } from 'vue-router'

import MainPage from '../pages/MainPage.vue'
import CreateProperty from '../pages/CreateProperty.vue'
import LoginPage from '../components/auth/LoginPage.vue'
// import RegisterPage from '../components/auth/RegisterPage.vue'
import HomePage from '../pages/HomePage.vue'
import AuthPage from '../pages/AuthPage.vue'
import EditProfile from '../pages/EditProfile.vue';
import EditProperty from '../pages/EditProperty.vue';

import i18n from "@/i18n";

const routes = [
    {
        path: '/:lang?',
        component: MainPage,
        name: 'home',
        children: [
            { path: '/', name: 'home', component: HomePage },
            { path: '/:lang?/personal/add', name: 'add', component: CreateProperty },
            { path: '/:lang?/personal/edit/:id', name: 'edit', component: EditProperty },
            { path: '/:lang?/profile/edit', name: 'editProfile', component: EditProfile }
        ]
    },
    {
        path: '/:lang?/auth/',
        component: AuthPage,
        name: 'auth',
        children: [
            { path: 'login', name: 'login', component: LoginPage},
            // { path: 'register', name: 'register', component: RegisterPage }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(), //Для серверного рендеринга можно использовать createMemoryHistory, для SPA - createWebHistory
    routes,
})

router.beforeEach((to, from, next) => {
    const lang = to.params.lang || 'hy'; // По умолчанию используем стандартный язык (hy)
    i18n.locale = lang; // Устанавливаем язык в i18n
    if(to.name != 'auth' && to.name != 'login' && to.name != 'register') {
        checkAuth(to, from, next);
    }
    next();
});

const checkAuth = async (to, from, next) => {
        const res = await fetch(process.env.VUE_APP_BACK_URL + '/api/v1/me', {
            headers: {
                'Authorization': 'Bearer '+ localStorage.getItem('garant_token'),
                'Accept': 'application/json; charset=utf-8'
            },
            method: 'GET',
        });
    
        const data = await res.json();
        if(data.error){
            router.push({name:'login'});
        } else {
            next();
        }
}

export default router; 