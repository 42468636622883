<template>
  <div class="property">
    <div class="container">
      <h2>Обновить профиль</h2>
      <el-form
        ref="editProfileRef"
        :model="profileForm"
        :rules="rules"
        label-position="top"
        label-width="auto"
        :inline="false"
        size="default"
      >
        <el-form-item :prop="'first_name'" style="width: 100%">
          <el-input
            class="custom_input"
            v-model="profileForm['first_name']"
            type="text"
            :placeholder="'Ваше имя'"
          />
        </el-form-item>

        <el-form-item :prop="'last_name'" style="width: 100%">
          <el-input
            class="custom_input"
            v-model="profileForm['last_name']"
            type="text"
            :placeholder="'Ваша фамилия'"
          />
        </el-form-item>

        <el-form-item :prop="'phone'" style="width: 100%">
          <el-input
            class="custom_input"
            v-model="profileForm['phone']"
            type="text"
            :placeholder="'Ваш номер телефона'"
          />
        </el-form-item>

        <el-form-item :prop="'dob'" style="width: 100%">
          <el-date-picker
            v-model="profileForm['dob']"
            type="date"
            placeholder="Ваша дата рождения"
            :size="size"
          />
        </el-form-item>

        <el-form-item :prop="'description'" style="width: 100%">
          <el-input
            class="custom_input"
            v-model="profileForm['description']"
            type="text"
            :placeholder="'Информация о компании'"
          />
        </el-form-item>

        <!-- 
        
                    'first_name' => 'required|max:120|min:2',
            'last_name' => 'required|max:120|min:2',
            'phone' => 'required|max:15|min:8',
            'dob' => 'required|max:15|min:8',
            'gender' => 'nullable',
            'description' => 'nullable',
            'email' => 'nullable|max:60|min:6|email|unique:' . ApiHelper::getTable() . ',email,' . $userId,
        -->

        <el-form-item class="el_form-submit" style="width: 100%">
          <el-button
            class="property_next"
            type="primary"
            @click="onSubmit(editProfileRef)"
            >Обновить</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { provide, ref, reactive, onMounted } from "vue";
import { ElNotification } from "element-plus";
import { useRouter } from "vue-router";

const router = useRouter();
const rules = reactive({});
const editProfileRef = ref();
const profileForm = reactive([]);
const loading = ref(false);

const objectToUrlParams = (formData) => {
  const urlParams = new URLSearchParams();

  // Заполняем urlParams данными из formData
  for (const [key, value] of Object.entries(formData)) {
    if (key == "dob") {
      urlParams.append(key, value.toDateString());
    } else {
      urlParams.append(key, value);
    }
  }

  return urlParams;
};

const submitForm = async (formData) => {
  loading.value = true;
  let urlParams = objectToUrlParams(formData);
  let res = await fetch(
    process.env.VUE_APP_BACK_URL + "/api/v1/me?" + urlParams,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("garant_token"),
        Accept: "application/json; charset=utf-8",
      },
      method: "PUT",
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      router.push({ name: "login" });
    }
    let result = await res.json();
    if (result.error) {
      ElNotification({
        type: "error",
        title: "Ошибка",
        message: result.message,
        position: "bottom-right",
      });
      loading.value = false;
      return;
    }
  } else {
    let result = await res.json();
    if (result.data) {
      ElNotification({
        type: "success",
        title: "Успех",
        message: "Профиль успешно обновлен",
        position: "bottom-right",
      });
      loading.value = false;
    }
  }
};

const onSubmit = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      submitForm(profileForm);
    } else {
      console.log("error submit!", fields);
    }
  });
};

const getUserInfo = async () => {
  const res = await fetch(process.env.VUE_APP_BACK_URL + "/api/personal/user", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("garant_token"),
      Accept: "application/json; charset=utf-8",
    },
    method: "GET",
  });
  if (!res.ok) {
    if (res.status === 401) {
      router.push({ name: "login" });
    }
  } else {
    const result = await res.json();

    profileForm["first_name"] = result.data["first_name"];
    profileForm["last_name"] = result.data["last_name"];
    profileForm["phone"] = result.data["phone"];
    profileForm["dob"] = result.data["dob"];
    profileForm["description"] = result.data["description"];
  }
};

const addRule = (key, required, message, trigger = "change") => {
  rules[key] = [
    {
      required: required,
      message: message,
      trigger: trigger,
    },
  ];
};

const goBack = () => {
  history.go(-1);
};

onMounted(() => {
  addRule("first_name", true, "Введите имя");
  addRule("last_name", true, "Введите фамилию");
  addRule("phone", true, "Введите номер телефона");
  addRule("dob", true, "Введите дату рождения");
  addRule("description", false, "Введите описание");
  getUserInfo();
});

provide("GoBack", goBack);
</script>

<style lang="scss">
.property {
  //margin-top: 30px;

  // .el-collapse-item {
  //     border
  // }
  &_next {
    height: 44px;
    background-color: #2d84ec;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
    color: #f8f8f8;
  }

  .el-collapse-item__header {
    height: auto;
    border-bottom: none;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 30px;
  }

  .el-collapse-item__wrap {
    border-bottom: none;
    overflow: visible;
  }

  .el-collapse {
    margin-top: 38px;
    border-top: 0px;
    border-bottom: 0px;
  }

  .collapse_item-title {
    font-size: 16px;
    font-weight: 600;
    &_required {
      color: #f00;
    }
  }

  .collapse_content {
    border-radius: 20px;
    background: #f8f8f8;
    padding: 20px;
  }

  .el-row {
    margin-bottom: 20px;
  }

  .select_item-100 {
    width: 100%;
  }

  .custom_input {
    input,
    textarea {
      border-radius: 12px;
    }
  }

  .label {
    font-size: 14px;
    margin-bottom: 18px;
  }
  .el-upload-dragger {
    height: 50px;
  }
}
</style>
