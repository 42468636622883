<template>
  <div class="userinfo">
    <div class="userinfo_main">
      <div class="avatar">
        <img class="avatar_img" src="../assets/avatar.svg" alt="" />
        <img src="../assets/edit-photo.svg" alt="" class="avatar_edit" />
      </div>
      <div class="main_info" v-if="userInfo">
        <div class="main_info-name">{{ userInfo.name }}</div>
        <button class="main_info-button click_animation" @click="goEditProfile()">
          {{ $t("go_to_profile") }}
          <img src="../assets/right-arrow.svg" alt="" />
        </button>
      </div>
    </div>
    <!-- <div class="money_info">
            <div class="cashback">
                <div class="cashback_title">CASHBACK</div>
                <div class="cashback_value">
                    0 <span class="cashback_value-currency">֏</span>
                </div>
            </div>
            <div class="balance">
                <div class="balance_main">
                    <div class="balance_main__title">Баланс</div>
                    <div class="balance_main__value">
                        {{ userInfo.credits }}
                        <span class="balance_main__value-currency">֏</span>
                    </div>
                </div>
                <button class="balance_add click_animation">
                    Пополнить счет
                </button>
            </div>
        </div> -->
    <!--        <div class="info">-->
    <!--            <img src="../assets/info.svg" alt="" />-->
    <!--        </div>-->
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const userInfo = ref([]);

const goEditProfile = () => {
  router.push({ name: "editProfile" });
}

const getUserInfo = async () => {
  const res = await fetch(process.env.VUE_APP_BACK_URL + "/api/personal/user", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("garant_token"),
      Accept: "application/json; charset=utf-8",
    },
    method: "GET",
  });
  if (!res.ok) {
    if (res.status === 401) {
      router.push({ name: "login" });
    }
  } else {
    const result = await res.json();

    userInfo.value = result.data;
  }
};

onMounted(() => {
  getUserInfo();
});
</script>

<style scoped lang="scss">
.userinfo {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  .info {
    cursor: pointer;
  }
}

.userinfo_main {
  display: flex;
  justify-content: space-between;
}

.main_info {
  display: flex;
  flex-direction: column;

  &-name {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  &-button {
    width: 145px;
    height: 24px;
    background-color: #d9d9d9;
    border-radius: 4px;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;

    &:active {
      transform: scale(0.95);
    }

  }
}

.avatar {
  position: relative;
  box-sizing: border-box;
  height: fit-content;
  width: fit-content;
  margin-right: 15px;

  &_img {
    width: 44px;
    height: 44px;
  }

  &_edit {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}

.money_info {
  display: flex;

  .cashback {
    margin-right: 14px;
    width: 107px;
    height: 67px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    border-radius: 12px;
    border: 1px solid #e6e6e6;
    &_title {
      color: #ff24af;
      text-align: center;
      font-size: 10px;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
    &_value {
      display: flex;
      justify-content: center;
      font-size: 26px;
      color: #000;
      font-weight: 700;
      &-currency {
        color: #d9d9d9;
        font-size: 26px;
        margin-left: 5px;
      }
    }
  }

  .balance {
    width: 234px;
    height: 67px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #e6e6e6;
    &_main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &__title {
        color: #000;
        font-size: 10px;
        font-weight: 400;
        text-transform: uppercase;
      }
      &__value {
        display: flex;
        justify-content: center;
        font-size: 26px;
        color: #000;
        font-weight: 700;
        &-currency {
          color: #d9d9d9;
          font-size: 26px;
          margin-left: 5px;
        }
      }
    }
    &_add {
      width: 103px;
      height: 47px;
      border-radius: 7px;
      background: linear-gradient(138deg, #ff068f 0%, #fa1dff 100%);
      color: #fff;
      font-size: 10px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  .click_animation {
    &:active {
      transform: scale(0.96);
      transition-duration: 0.3s;
    }
  }
}
</style>
