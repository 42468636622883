<template>
    <el-collapse-item class="collapse_item" :title="$t('orders')" name="1">
        <div class="collapse_item-swiper">
            <div class="swiper-wrapper">
                <div
                    class="swiper-slide"
                    v-for="(card, index) in myProperties"
                    v-show="card.status.value != 'approved'"
                    :key="index"
                >
                    <PropertyCard :card="card"></PropertyCard>
                </div>
            </div>
        </div>
    </el-collapse-item>
</template>


<script setup>
    import { inject, onMounted } from "vue";
    import PropertyCard from "./PropertyCard.vue";
    import Swiper from "swiper";

    const myProperties = inject("MyProperties");

    const initSwiper = () => {
        new Swiper(".collapse_item-swiper", {
                slidesPerView: 'auto',
                centeredSlides: false,
                // initialSlide: 1,
                // loop: true,
                spaceBetween: 15,
                pagination: false,
                navigation: false,
                breakpoints: {
                    575: {
                        slidesPerView: 'auto',
                    },
                    320: {
                        centeredSlides: true,
                        slidesPerView: 'auto',
                    },
                },
            });
    }

    onMounted(() => {
        initSwiper();
    });


</script>

<style lang="scss" scoped>
.collapse_item {
    &-swiper {
        margin-top: 20px;
    }

    .swiper-slide {
        width: 250px;
    }
}
</style>
