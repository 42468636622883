<template>
    <el-collapse-item class="collapse_item" name="9">
        <template #title>
            <div class="collapse_item-title">
                {{props.number}}. {{ $t("video") }}
                <span class="collapse_item-title_required">*</span>
            </div>
        </template>
        <div class="collapse_content">
            <div class="video_links">
                <div class="video_links-list">
                    <div
                        class="video_item-link custom_input"
                        v-for="(item, index) in propertyForm['video_links']"
                        :key="index"
                    >
                    <el-form-item class="video_item-link_input" :prop="'video_links'">
                        <el-input
                            v-model="propertyForm['video_links'][index].value"
                            :placeholder="$t('embed_video')"
                        ></el-input>
                        <el-icon @click="removeLink(index)" class="remove_link" ><Remove /></el-icon>
                    </el-form-item>
                    </div>
                </div>
                <span class="video_links-add" @click="addLink">
                    <el-icon><CirclePlus /></el-icon>
                    {{ $t("add_more") }}
                </span>
            </div>
        </div>
    </el-collapse-item>
</template>


<script setup>

    import {defineProps, inject } from "vue";

    const props = defineProps(['number']);

    const propertyForm = inject("PropertyForm");

    const addLink = () => {
        propertyForm['video_links'].push({ value: "" });
    };

    const removeLink = (index) => {
        propertyForm['video_links'].splice(index, 1);
    }

</script>

<style lang="scss">

.video_links {
    &-list {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .video_item-link {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;

            .el-form-item__content {
                    flex-direction: row;
                    flex-wrap: nowrap !important;
                    gap: 15px;
                }

            &_input {
                width:100%;
            }

            .remove_link {
                cursor: pointer;
                color: #ff0000;
            }
        }
    }
    &-add {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: 30px;
        gap: 15px;
        font-size: 14px;
        color: #2d84ec;
    }
}
</style>
