<template>
  <div class="property">
    <div class="container">
      <el-form
        ref="propertyFormRef"
        :model="propertyForm"
        :rules="rules"
        label-position="top"
        label-width="auto"
        :inline="false"
        size="default"
      >
        <el-collapse v-model="activeNames">
          <NameProperty :number="1"></NameProperty>
          <PriceProperty :number="2"></PriceProperty>
          <DescriptionProperty :number="3"></DescriptionProperty>
        </el-collapse>

        <el-form-item
          class="el_form-submit"
          style="width: 100%"
        >
          <el-button
            class="property_next"
            type="primary"
            @click="onSubmit(propertyFormRef)"
            >Сохранить</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { provide, ref, reactive, onMounted } from "vue";
import { ElNotification } from "element-plus";
import { useRouter } from "vue-router";
import NameProperty from "@/components/property/NameProperty.vue";
import PriceProperty from "@/components/property/PriceProperty.vue";
import DescriptionProperty from "@/components/property/DescriptionProperty.vue";


const router = useRouter();
const selectedCategory = ref(null);
const activeNames = ref(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]);
const rules = reactive({});
const propertyFormRef = ref();
const propertyForm = reactive([]);
const loading = ref(false);
const createdProperty = ref([]);

const SelectCategory = function (value) {
  selectedCategory.value = value;
};

const objectToFormData = (obj, formData, parentKey) => {
  formData = formData || new FormData();
  for (let key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const propName = parentKey ? `${parentKey}[${key}]` : key;
      const value = obj[key];

      if (value instanceof File) {
        // Если значение является файлом, добавляем его напрямую в FormData
        formData.append(propName, value);
      } else if (value instanceof Object && !(value instanceof File)) {
        // Если значение является объектом, рекурсивно вызываем функцию
        if (value[0] instanceof Date) {
          formData.append(`${propName}[start]`, value[0]);
          formData.append(`${propName}[end]`, value[1]);
        } else if (value instanceof Date) {
          formData.append(propName, value);
        } else {
          objectToFormData(value, formData, propName);
        }
      } else {
        // В противном случае добавляем обычное значение в FormData
        formData.append(propName, value);
      }
    }
  }
  return formData;
};

const submitForm = async (formData) => {
  loading.value = true;
    
  let res = await fetch(process.env.VUE_APP_BACK_URL + "/api/property/update", {
    headers: {
      Authorization: 'Bearer '+ localStorage.getItem('garant_token'),
      Accept: "application/json; charset=utf-8",
    },
    method: "POST",
    body: objectToFormData(formData),
  });

  if (!res.ok) {
    if (res.status === 401) {
      router.push({ name: "login" });
    }
  } else {
    let result = await res.json();

    if (result.data) {
      ElNotification({
        type: "success",
        title: "Успех",
        message: "Объявление отправлено на рассмотрение",
        position: "bottom-right",
      });
      loading.value = false;
      createdProperty.value = result.data;
    } else {
      ElNotification({
        type: "error",
        title: "Ошибка",
        message: "Ошибка при редактировании объявления",
        position: "bottom-right",
      });
      loading.value = false;
    }
  }
};

const getPropertyData = async () => {
  let res = await fetch(process.env.VUE_APP_BACK_URL + "/api/property/show/" + router.currentRoute.value.params.id, {
    headers: {
      Authorization: 'Bearer '+ localStorage.getItem('garant_token'),
      Accept: "application/json; charset=utf-8",
    },
    method: "GET",
  });

  if (!res.ok) {
    if (res.status === 401) {
      router.push({ name: "login" });
    }
  } else {
    let result = await res.json();
    if (result.data) {
        for(let i in result.data) {
            propertyForm[i] = result.data[i];
        }
      console.log(propertyForm);
    } else {
      ElNotification({
        type: "error",
        title: "Ошибка",
        message: "Ошибка при загрузке объявления",
        position: "bottom-right",
      });
    }
  }

};

const onSubmit = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      submitForm(propertyForm);
    } else {
      console.log("error submit!", fields);
    }
  });
};

const makeForm = () => {
  addRule("name", true, "Заполните название");
  addRule("price", false, "Укажите цену продажи");
  addRule("change_content", false, "Укажите цену обмена");
  addRule("price_rent_normal", false, "Укажите цену аренды");

  addRule("description", true, "Добавьте описание");
};


const addRule = (key, required, message, trigger = "change") => {
  rules[key] = [
    {
      required: required,
      message: message,
      trigger: trigger,
    },
  ];
};

onMounted(() => {
  makeForm();
  getPropertyData();
});

const goBack = () => {
  history.go(-1);
};


provide("GoBack", goBack);
provide("SelectedCategory", selectedCategory);
provide("SelectCategory", SelectCategory);
provide("PropertyForm", propertyForm);
provide("CreatedProperty", createdProperty);
</script>

<style lang="scss">
.property {
  //margin-top: 30px;

  // .el-collapse-item {
  //     border
  // }
  &_next {
    height: 44px;
    background-color: #2d84ec;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
    color: #f8f8f8;
  }

  .el-collapse-item__header {
    height: auto;
    border-bottom: none;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 30px;
  }

  .el-collapse-item__wrap {
    border-bottom: none;
    overflow: visible;
  }

  .el-collapse {
    margin-top: 38px;
    border-top: 0px;
    border-bottom: 0px;
  }

  .collapse_item-title {
    font-size: 16px;
    font-weight: 600;
    &_required {
      color: #f00;
    }
  }

  .collapse_content {
    border-radius: 20px;
    background: #f8f8f8;
    padding: 20px;
  }

  .el-row {
    margin-bottom: 20px;
  }

  .select_item-100 {
    width: 100%;
  }

  .custom_input {
    input,
    textarea {
      border-radius: 12px;
    }
  }

  .label {
    font-size: 14px;
    margin-bottom: 18px;
  }
  .el-upload-dragger {
    height: 50px;
  }
}
</style>
