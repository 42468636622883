<template>
    <div class="stages">
        <div class="stages_list">
            <div
                class="stages_list-item"
                :class="{ fullfil: StepNumber > 1, active: StepNumber == 1 }"
            >
                <div class="stages_list-item-int">1</div>
                <div class="stage_list-item-label">
                    {{ $t("select_category") }}
                </div>
            </div>
            <div
                class="stages_list-item"
                :class="{ fullfil: StepNumber > 2, active: StepNumber == 2 }"
            >
                <div class="stages_list-item-int">2</div>
                <div class="stage_list-item-label">{{ $t("information") }}</div>
            </div>
            <!-- <div
                class="stages_list-item"
                :class="{ fullfil: stepNumber > 3, active: stepNumber == 3 }"
            >
                <div class="stages_list-item-int">3</div>
                <div class="stage_list-item-label">Проверка</div>
            </div> -->
            <div class="stages_list-item" :class="{ fullfil: StepNumber == 3 }">
                <div class="stages_list-item-int">3</div>
                <div class="stage_list-item-label">{{ $t("done") }}</div>
            </div>
        </div>
    </div>
</template>

<script setup>
  import {inject} from 'vue';

  const StepNumber = inject('StepNumber');

</script>

<style lang="scss" scoped>
.stages {
    margin: 30px;
    &_list {
        display: flex;
        flex-direction: flex-start;
        gap: 10px;
        &-item {
            padding: 5px 16px 5px 5px;
            height: 32px;
            display: flex;
            align-items: center;
            gap: 13px;
            border: 1px solid #d9d9d9;
            border-radius: 16px;
            &-int {
                background: #d9d9d9;
                width: 22px;
                height: 22px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                border-radius: 50%;
                font-weight: 600;
            }
            &-label {
                font-size: 12px;
            }
        }
        .fullfil {
            background: #20c374;
            border: 1px solid #20c374;
            color: #fff;
            .stages_list-item-int {
                background: #33a66e;
            }
        }

        .active {
            background: #ebf4ff;
            border: 1px solid #2d84ec;
            color: #fff;
            .stages_list-item-int {
                background: #2d84ec;
            }
            .stage_list-item-label {
                color: #2d84ec;
            }
        }
    }
}
</style>
