<template>
    <el-collapse-item class="collapse_item" name="5">
        <template #title>
            <div class="collapse_item-title">
                {{props.number}}. {{ $t("cost") }}
                <span class="collapse_item-title_required">*</span>
            </div>
        </template>
        <div class="collapse_content">
            <div class="label">{{ $t("sale_price") }}</div>
            <el-row :gutter="25">
                <el-col :span="12">
                    <el-form-item :prop="'price'" style="width: 100%;">
                        <el-input
                            class="select_item-100 custom_input"
                            :placeholder="$t('cost')"
                            v-model="propertyForm['price']"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="label">{{ $t("rent_price") }}</div>
            <el-row :gutter="25">
                <el-col :span="12">
                    <el-form-item :prop="'price_rent_normal'" style="width: 100%;">
                        <el-input
                            class="select_item-100 custom_input"
                            :placeholder="$t('cost')"
                            v-model="propertyForm['price_rent_normal']"
                        />
                     </el-form-item>
                </el-col>
            </el-row>
            <div class="label">{{ $t("exchange") }}</div>
            <el-row :gutter="25">
                <el-col>
                    <el-form-item :prop="'change_content'" style="width: 100%;">
                        <el-input
                            class="select_item-100 custom_input"
                            type="textarea"
                            :placeholder="$t('exchange_example')"
                            v-model="propertyForm['change_content']"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
    </el-collapse-item>
</template>

<script setup>

    import {defineProps, inject } from 'vue';

    const props = defineProps(['number']);

    const propertyForm = inject('PropertyForm');
</script>

<style lang="scss" scoped></style>
