<template>
    <el-collapse-item class="collapse_item" name="1">
        <template #title>
            <div class="collapse_item-title">
                {{ $t("your_status") }}
                <span class="collapse_item-title_required">*</span>
            </div>
        </template>
        <div class="collapse_content">
            <div class="status_list">
                <div class="status_list-item">
                    <div class="item_right">
                        <img
                            src="../assets/primary_circle.svg"
                            alt=""
                        />
                    </div>
                    <div class="item_left">
                        <div class="item_left-title">{{ $t("owner") }}</div>
                        <div class="item_left-content">
                            {{ $t("owner_description") }}
                        </div>
                    </div>
                </div>
                <div class="status_list-item">
                    <div class="item_right">
                        <img
                            src="../assets/error_circle.svg"
                            alt=""
                        />
                    </div>
                    <div class="item_left">
                        <div class="item_left-title">{{ $t("agency") }}</div>
                        <div class="item_left-content">
                            <div class="warning">
                                {{ $t("agency_description") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-collapse-item>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.status_list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-item {
        display: flex;
        gap: 20px;

        .item_left {
            display: flex;
            flex-direction: column;
            gap: 10px;
            &-title {
                font-size: 15px;
            }
            &-content {
                font-size: 12px;
                opacity: 0.5;
                .warning {
                    padding: 10px;
                    border-radius: 8px;
                    background: #F9B9B9;
                }
            }
        }
    }
}
</style>
