<template>
  <div class="container main_personal">
    <el-container>
      <el-container>
        <el-aside class="main_aside">
          <left-menu></left-menu>
        </el-aside>
        <el-container>
          <el-header class="main_header">
            <el-menu
              text-color="#FF169B"
              :default-active="activeIndex"
              class="main_header_menu"
              mode="horizontal"
              :ellipsis="false"
              @select="handleSelect"
            >
              <el-menu-item index="0">
                <img
                  style="width: 200px"
                  src="../assets/logo-garant.am.png"
                  alt="Element logo"
                />
              </el-menu-item>
              <div class="flex-grow" />
              <el-sub-menu index="1">
                <template #title
                  ><el-icon><Menu /></el-icon
                ></template>
                <el-menu-item index="2-1" @click="goHome()">Мой аккаунт</el-menu-item>
                <el-menu-item index="2-2" @click="logout()">Выход</el-menu-item>
              </el-sub-menu>
            </el-menu>
          </el-header>
          <el-main class="main_block">
            <RouterView></RouterView>
          </el-main>
          <!-- <el-footer>Footer</el-footer> -->
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>
<script setup>
import {ref} from "vue";
import {useRouter} from "vue-router";
import {ElNotification} from "element-plus";
import LeftMenu from "../components/LeftMenu.vue";

const router = useRouter();
const loading = ref(false);

const goHome = () => {
  router.push({ name: "home" });
}

const logout = async () => {
  const res = await fetch(process.env.VUE_APP_BACK_URL + "/api/v1/logout", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("garant_token"),
      Accept: "application/json; charset=utf-8",
    },
    method: "GET",
  });

  if (!res.ok) {
    if (res.status === 401) {
      router.push({ name: "login" });
    }
  } else {
    const result = await res.json();

    if (!result.error) {
      ElNotification({
        type: "success",
        title: "Успех",
        message: result.message,
        position: "bottom-right",
      });
      loading.value = false;
      location.reload();
    } else {
      ElNotification({
        type: "error",
        title: "Ошибка",
        message: result.message,
        position: "bottom-right",
      });
      loading.value = false;
    }
  }
};

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.logo {
  width: 200px;
}

.main_header {
  width: 100%;
  border-bottom: 1px solid #ebeef5;

  &_menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.main_block {
  height: 100%;
}

.main_aside {
  width: auto;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

</style>
